import React from 'react';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';
import Box, { Spacing } from '@webAtoms/Box/Box';
import Button from '@webAtoms/Button/Button';
import Container from '@webAtoms/Container/Container';
import BrochureForm from '@webSections/BrochureForm';
import Accordion from '@webMolecules/Accordion/Accordion';
import UpNext from '@webMolecules/UpNext/UpNext';
import ModularHomeDesigns from '@webOrganisms/ModularHomeDesigns/ModularHomeDesigns';
import FeaturedBuilds from '@webSections/FeaturedBuilds';
import PageHeading from '@webMolecules/PageHeading/PageHeading';
import * as Route from '@routes';
import * as styles from './custom.module.scss';
import { render } from '@helpers/markdown';

export interface CustomProps {
  title: string;
  body: string;
  heroImage: IGatsbyImageData;
  columns: Column[];
  faqSections: FaqSection[];
}

export interface Column {
  title: string;
  body: string;
  parts: Part[];
}

export interface FaqSection {
  title: string;
  parts: Part[];
}

export interface Part {
  title: string;
  body: string;
}

const Custom: React.FC<CustomProps> = ({
  title,
  body,
  heroImage,
  columns,
  faqSections,
}) => {
  return (
    <Box>
      <PageHeading title={title}>
        <Box className={styles.customBody} htmlContent={body} />
      </PageHeading>
      <Container>
        <Box className={styles.customInner}>
          <Box className={styles.customHeroImageWrapper}>
            <GatsbyImage loading="lazy" alt={title} image={heroImage} />
          </Box>

          <Box className={styles.customGrid}>
            <Box>
              {columns.map((column, i) => (
                <Box key={i}>
                  <Box
                    className={[
                      styles.customSectionHeading,
                      column.parts?.length === 0
                        ? styles.customSectionHeadingNoParts
                        : '',
                    ].join(' ')}
                  >
                    <h2>{column.title}</h2>
                    <Box htmlContent={render(column.body, {})} />
                  </Box>

                  <Box className={styles.customSectionSteps}>
                    <ul>
                      {column.parts.map((part, i) => (
                        <li>
                          <h3
                            dangerouslySetInnerHTML={{
                              __html: part.title.split('\n').join('<br />'),
                            }}
                          />
                          <Box htmlContent={render(part.body, {})} />
                          {i === column.parts.length - 1 && (
                            <Box desktop paddingTop={Spacing.Xl}>
                              <Button
                                intent="primary"
                                icon="arrowRight"
                                href={Route.contactUs()}
                              >
                                Enquire
                              </Button>
                            </Box>
                          )}
                        </li>
                      ))}
                    </ul>
                  </Box>
                </Box>
              ))}
            </Box>

            <Box className={styles.customFaqs}>
              {faqSections.map(section => (
                <Box className={styles.customFaq}>
                  <Box key={section.title} className={styles.customFaqInner}>
                    <Box className={styles.faqsSectionHeading}>
                      <h2>{section.title}</h2>
                    </Box>
                    <Box className={styles.customFaqBody}>
                      {section.parts.map(part => (
                        <Accordion key={part.title} title={part.title}>
                          <Box
                            className={styles.customFaqBody}
                            htmlContent={render(part.body)}
                          />
                        </Accordion>
                      ))}
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>

        <Box mobile paddingTop={Spacing.Xl}>
          <Button
            fluid
            intent="primary"
            icon="arrowRight"
            href={Route.contactUs()}
          >
            Enquire
          </Button>
        </Box>
      </Container>

      <Box paddingTop={Spacing.Xl2} paddingBottom={Spacing.Xl2}>
        <BrochureForm />
      </Box>

      <FeaturedBuilds />

      <ModularHomeDesigns />

      <UpNext
        links={[
          { label: 'Our process', href: Route.ourProcess() },
          {
            label: 'Modular homes',
            href: Route.designs(),
          },
        ]}
      />
    </Box>
  );
};

export default Custom;
